@import '../../styles/import';

.component-checkmark {
  position: relative;
  width: 16px;
  height: 16px;
  $down: 0;
  $up: 0;
  $responsive: 0;
  $line: 0;
  $transition-duration: 150ms;

  &--responsive {
    $responsive: &;
  }

  &--large {
    transform: scale(1.25);

    &#{$responsive} {
      @include media-breakpoint-down(sm) {
        transform: none;
      }
    }
  }

  &__line {
    display: block;
    position: absolute;
    background-color: $digital-grass;
    height: 2px;
    transform-origin: left;
    border-radius: 5px;
    $line: &;

    &--down {
      $down: &;
      transform: rotate(-50deg) scalex(0);
      width: 13px;
      left: 5px;
      top: 12px;
    }

    &--up {
      $up: &;
      transform: rotate(50deg) scalex(0);
      width: 6px;
      left: 2px;
      top: 7px;
    }
  }

  &--ticked {
    #{$down} {
      transition: transform $transition-duration linear $transition-duration;
      transform: rotate(-50deg) scalex(1);
    }

    #{$up} {
      transition: transform $transition-duration linear;
      transform: rotate(50deg) scalex(1);
    }
  }

  &--white {
    #{$line} {
      background-color: white;
    }
  }
}
