@import '../../styles/import';

.component-button {
  $button: 0;
  $loading: 0;
  $icon: 0;
  $light-border-variant: 0;
  $default-light-variant: 0;

  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  &__button {
    position: relative;
    $button: &;
    @include type(default-semibold);
    border: 0;
    @include spacing(padding, xx-large, horizontal);
    border-radius: $border-radius-large;
    transition: color 200ms ease-in-out, box-shadow 200ms ease-in-out,
      border 200ms ease-in-out;
    white-space: nowrap;

    &__icon {
      $icon: &;
      display: inline-block;
      position: relative;
      top: -0.15em;
      vertical-align: middle;
      @include spacing(margin-left, x-small);
    }

    &::after {
      $size: 24px;
      $loading: &;
      content: '';
      position: absolute;
      height: $size;
      width: $size;
      top: 50%;
      left: 50%;
      margin-top: -($size / 2);
      margin-left: -($size / 2);
      background: url('./spinner.png') no-repeat center center;
      background-size: $size $size;
      opacity: 0;
      animation: spin 2000ms ease-in-out infinite;
      transition: opacity 200ms ease-in-out;
      pointer-events: none;
    }
  }

  &--primary #{$button} {
    color: $explore-white;
    background-color: $link-color;

    &:hover {
      box-shadow: 0 0 0 2px $link-color, inset 0 0 0 1px $link-color;
    }
  }

  &--light #{$button} {
    color: $copy-color;
    background-color: $explore-white;

    &:hover {
      box-shadow: 0 0 0 2px $explore-white, inset 0 0 0 1px $explore-white;
    }
  }

  &--dark #{$button} {
    color: $explore-white;
    background-color: $copy-color;

    &:hover {
      box-shadow: 0 0 0 2px $copy-color, inset 0 0 0 1px $copy-color;
    }
  }

  // DNA-inspired naming
  &--default-light #{$button} {
    $default-light-variant: &;

    background-color: transparent;
    color: $explore-white;
    border: 1px solid $explore-white;

    &:hover {
      box-shadow: 0 0 0 1px $digital-grass;
      border-color: transparent;
    }
  }

  &--light-bordered #{$button} {
    $light-border-variant: &;
    color: $copy-color;
    background-color: $explore-white;
    border: 1px solid $copy-color;
    &--transparent {
      background-color: inherit;
    }

    &:hover {
      border-color: transparent;
      box-shadow: 0 0 0 1px $digital-grass;
    }
  }

  &--large {
    #{$icon} {
      width: 1.25rem;
      height: 1.25rem;
    }

    #{$button} {
      height: 3rem;
    }

    &#{$light-border-variant},
    &#{$default-light-variant} {
      border-width: 2px;

      &:hover {
        box-shadow: 0 0 0 2px $digital-grass;
      }
    }
  }

  @mixin button-small {
    #{$icon} {
      width: 1rem;
    }

    font-size: 0.875rem;
    height: 2rem;
    @include spacing(padding, small, horizontal);
  }

  &--small #{$button} {
    @include button-small;
  }

  &--disabled #{$button} {
    color: $copy-color;
    background-color: $light-grey-two;
    cursor: default;

    &:hover {
      box-shadow: none;
    }
  }
  &--link-button {
    @include spacing(padding-top, x-small);
    @include spacing(padding-bottom, x-small);
    text-align: center;
    display: inline-flex;
    align-items: center;
  }

  @include media-breakpoint-down(sm) {
    &--responsive {
      #{$button} {
        @include button-small;
      }
      &#{$light-border-variant} {
        border-width: 1px;
      }
    }
  }

  &--block #{$button} {
    width: 100%;
  }

  &--block-centered {
    @include spacing(margin-top, small);

    @include phone() {
      @include spacing(margin-top, x-small);
    }

    #{$button} {
      width: 287px;

      @media (min-width: $screen-tablet) {
        width: 336px;
      }
    }
  }

  &--inline {
    display: inline-flex;

    #{$button} {
      @include spacing(padding, x-large, horizontal);
    }
  }

  &--loading {
    #{$loading} {
      opacity: 1;
    }
    #{$button} {
      cursor: none;
      pointer-events: none;
      color: $link-color;
    }
  }

  &--link #{$button} {
    color: $link-color;
    background-color: transparent;
    height: auto;
    @include type(default);
    padding: 0;
    cursor: pointer;

    &:hover {
      box-shadow: none;
    }
  }

  & + & {
    @include spacing(margin-left, x-small);
  }
}

@keyframes spin {
  100% {
    transform: rotate(1440deg);
  }
}
