// These are mixins instead of placeholders because they
// should be usable across all files and media queries.

@mixin selectable {
  border-radius: $border-radius-base;
  transition: box-shadow 150ms ease-in-out, background-color 150ms ease-in-out,
    border-color 150ms ease-in-out;
}

@mixin selectable--selected {
  background-color: $explore-white;
  box-shadow: 0 0 16px 0 $shadow-color;
}

@mixin selectable--hovered {
  box-shadow: 0 0 16px 0 rgba($shadow-color, 0.05);
}

@mixin scale-content {
  @media (max-width: $scale-breakpoint) {
    transform: scale(0.9);
  }
}

@mixin screenreaders-only {
  // Only show content to screen readers
  position: absolute;
  left: -10000px;
  top: auto;
  width: 1px;
  height: 1px;
  overflow: hidden;
}

// IE10+ - REALLY hope this is never used, but I don't trust either Humac nor Elgiganten :(
@mixin gteIE10 {
  @media screen and (-ms-high-contrast: active),
    screen and (-ms-high-contrast: none) {
    @content;
  }
}
