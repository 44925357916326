@import '../../styles/import.scss';

.customer-lookup {
  @include spacing(margin-top, large);

  &__details {
    &__title {
      color: $tekstGrøn;
      text-align: center;
      @include type(tertiary-title);
      @include spacing(margin, large, vertical);
    }

    &__subscriptions {
      width: 100%;
      border-collapse: separate;
      border-spacing: 0 32px;

      &__header {
        @include type(default-semibold-small);
      }

      td {
        @include spacing(padding, small, horizontal);
        @include spacing(padding, small, vertical);

        &:first-child {
          @include spacing(padding-left, large);
        }
      }

      &__binding {
        position: absolute;
        width: auto;
        display: flex;
        align-items: center;
        background-color: $feedback-red;
        color: white;
        border-radius: 8px;
        padding: 4px 8px;
        font-weight: 500;
        right: 0;
        top: -20px;
        font-size: 12px;
        z-index: 1;

        &__exlamation {
          width: 18px;
          height: 18px;
          line-height: 18px;
          text-align: center;
          font-size: 16px;
          color: $feedback-red;
          border-radius: 50%;
          flex-shrink: 0;
          background-color: white;
          @include spacing(margin-right, x-small);
        }
      }

      &__body {
        tr {
          position: relative;
          @include spacing(margin, medium, vertical);

          td {
            border-top: 1px solid $mid-grey;
            border-bottom: 1px solid $mid-grey;

            &:first-child {
              position: relative;

              &::before {
                content: '';
                display: block;
                position: absolute;
                width: 6px;
                height: calc(100% + 2px);
                background-color: $primary-green;
                left: 1px;
                top: -1px;
                border-top-left-radius: 8px;
                border-bottom-left-radius: 8px;
                z-index: 1;
              }

              &.in-progress {
                &::before {
                  background-color: $bold-orange;
                }
              }

              &::after {
                content: '';
                display: block;
                position: absolute;
                height: calc(100% + 2px);
                width: 7px;
                left: 0px;
                top: -1px;
                background-color: white;
              }
            }

            &:last-of-type {
              border-right: 1px solid $mid-grey;
              border-top-right-radius: 8px;
              border-bottom-right-radius: 8px;
            }
          }
        }
      }
    }
  }
}
