@import '../../styles/import.scss';

.order {
  &__configured,
  &__number-config {
    @include spacing(margin, large);
    @include spacing(padding, x-large);
    border: 2px solid #d8d8d8;
    border-radius: 8px;

    &__title {
      @include type(secondary-title);
      @include spacing(margin-bottom, medium);
      margin-top: 0;
      text-align: center;
    }

    &__number {
      @include spacing(padding, medium, horizontal);
      @include spacing(padding, x-small, vertical);
      @include type(default-semibold);
      background-color: $backgoundColor;
      border-radius: 24px;
      display: inline-block;
      margin-left: auto;
      margin-right: auto;
    }

    &__edit {
      @include spacing(margin-top, medium);
    }
  }

  &__configured {
    text-align: center;
  }

  &__basket {
    max-width: 700px;
    margin-left: auto;
    margin-right: auto;
    border: 1px solid $mid-grey;
    border-radius: 8px;
    @include spacing(padding, small, vertical);
    @include spacing(padding, medium, horizontal);
    @include spacing(margin-top, large);
    display: flex;
    justify-content: space-between;
    @include type(default-bold);

    &__title {
      @include type(default-bold);
    }

    &__input {
      max-width: 400px;
    }

    &__button {
      @include spacing(margin-top, large);
    }

    &__left {
      display: flex;

      &__icon {
        @include spacing(margin-right, medium);
      }
    }

    &__right {
      text-align: right;
    }
  }

  &__tabs {
    display: flex;
    justify-content: center;
    border: 1px solid $mid-grey;
    border-radius: 24px;
    @include spacing(margin-bottom, large);

    &__tab {
      background-color: white;
    }
  }

  &__more-numbers {
    text-align: center;
    color: $primary-green;
    @include spacing(margin-top, large);
    cursor: pointer;
  }

  &__spacing {
    @include spacing(margin-top, small);
    max-width: 396px;

    &__pinkode {
      @include spacing(margin-top, small);
      max-width: 328px;
    }

    &__checkbox {
      @include spacing(margin-top, small);
    }
  }

  &__button {
    @include spacing(margin-top, medium);
    max-width: 169px;
  }

  &__section {
    @include spacing(padding-top, small);

    &__title {
      @include type(default-semibold);
      @include type(tertiary-title);
      @include spacing(margin-bottom, medium);
      margin-top: 0;
    }
  }

  &__under-radio-label {
    @include type(default);
  }

  &__subscription {
    margin-left: auto;
    margin-right: auto;
    @include spacing(padding, small, vertical);
    @include spacing(padding, medium, horizontal);
    @include spacing(margin-top, large);
    display: flex;
    justify-content: space-between;
    @include type(default-semibold);

    &__left {
      display: flex;
    }

    &__right {
      text-align: right;
      color: #1a8924;
    }
  }

  &__details {
    max-width: 700px;
    margin-left: auto;
    margin-right: auto;
    border: 1px solid $mid-grey;
    border-radius: 8px;
    @include spacing(padding, small, vertical);
    @include spacing(padding, medium, horizontal);
    @include spacing(margin-top, large);

    hr {
      border: none;
      border-bottom: 1px solid $mid-grey;
      @include spacing(margin, medium, vertical);
      margin-left: -24px;
      width: calc(100% + 48px);
    }

    &__title {
      @include type(tertiary-title);
    }

    &__sim {
      max-width: 328px;
    }

    &__numbers {
      list-style: none;
      margin: 0;
      padding: 0;
      margin-top: 16px;
      margin-left: 0 !important;
      margin-right: 0 !important;

      &__number-search-wrap {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        background-color: $grey;
        border-radius: 10px;
        @include spacing(padding, medium);

        h3 {
          width: 100%;
          text-align: center;
        }

        p {
          width: 100%;
          text-align: center;
          margin-bottom: 0;
        }
      }

      &__number-search {
        border-radius: 10px;
        border: none;
        text-align: center;
        color: $primary-green;
        width: 40px;
        height: 40px;
        @include spacing(margin, medium, vertical);
        background-color: white;
        font-weight: bold;
        font-size: 18px;

        &:placeholder-shown {
          font-weight: normal;
          font-size: 12px;
        }

        &__button {
          width: 40px;
          overflow: hidden;
          border-radius: 10px;
          height: 40px;
          @include spacing(margin, medium, vertical);

          img {
            position: relative;
            left: -18px;
            top: 1px;
          }
        }
      }

      &__number {
        padding: 16px !important;
        border-radius: 16px;

        .green {
          color: $primary-green;
        }

        span {
          &:nth-child(even) {
            margin-right: 6px;
          }

          &:last-child {
            margin-right: 0;
          }
        }

        @media (max-width: $screen-sm-max) {
          padding: 12px !important;
        }

        &--selected {
          box-shadow: 0px 0px 16px 0px #0000003d;
        }

        label {
          display: block;
          width: 100%;
        }
      }
    }
  }
}
