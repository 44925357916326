/* elements.scss
 * lists, code, and more
 * --------------------- */

pre,
code,
kbd,
samp {
  color: red;
}

pre {
  display: block;
}

article h2,
article h3,
article h4 {
  margin-top: 0.5em;
}

body {
  background-color: $explore-white;
}

html {
  box-sizing: border-box;
}

iframe {
  border: 0;
}

.focus-within,
:focus {
  outline: 2px solid rgba($dark-sky-blue, 0.5);
  outline-offset: 4px;
}

.focus-within :focus {
  outline: 0;
}

// Visible to screen readers only
.visually-hidden {
  clip: rect(1px 1px 1px 1px);
  clip: rect(1px, 1px, 1px, 1px);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
  padding: 0;
}
