//@TODO Import should take care of everything import related, we'll split it up more later!!
//@import "~normalize.css";
@import 'import';

@import 'grid.scss';
@import 'typography.scss';

// Elements and forms
@import 'elements.scss';
@import 'forms.scss';

// Layout
@import 'layout.scss';

.load-screen {
  text-align: center;

  h1 {
    @include spacing(margin-bottom, xx-large);
  }
}
