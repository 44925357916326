@import 'import';

@import '~bootstrap/scss/mixins/grid-framework';
@import '~bootstrap/scss/mixins/grid';
@import '~bootstrap/scss/utilities/flex';
@import '~bootstrap/scss/utilities/display';
@import '~bootstrap/scss/grid';

* {
  box-sizing: border-box;
}

// Allow columns within a container and row to take
// up 100% width of the viewport in xs and sm sizes.
.container-fluid-md {
  @include media-breakpoint-down(sm) {
    padding-left: 0;
    padding-right: 0;
  }
}
