@import '../../styles/import.scss';

.approval {
  &__details {
    max-width: 700px;
    margin-left: auto;
    margin-right: auto;
    border: 1px solid $mid-grey;
    border-radius: 8px;
    @include spacing(padding, small, vertical);
    @include spacing(padding, medium, horizontal);
    @include spacing(margin-top, large);

    hr {
      border: none;
      border-bottom: 1px solid $mid-grey;
      @include spacing(margin, medium, vertical);
      margin-left: -24px;
      width: calc(100% + 48px);
    }

    &__title {
      @include type(tertiary-title);
    }

    &__basket {
      @include spacing(padding, medium);

      &__pdf-wrap {
        position: fixed;
        z-index: 2;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: rgba(black, 0.25);
      }

      &__pdf {
        position: relative;

        &__signature {
          width: calc(100% - 66px) !important;
          left: 29px;
          position: absolute;
          top: 256px;
          height: 140px !important;
        }
      }

      &__header {
        @include spacing(padding, large, horizontal);
        @include spacing(padding, small, vertical);
        @include spacing(margin, small, vertical);
        color: $copy-color;
        display: flex;
        justify-content: space-between;

        &--blue {
          background-color: $light-blue;
          border-radius: 8px;
        }

        &--space {
          @include spacing(margin-top, 4x-large);
        }
      }

      &__title {
        color: white;
        background: $mørkegrøn;
        @include spacing(padding, small, vertical);
        @include spacing(padding, large, horizontal);
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
      }

      &__subscriptions {
        margin: 0;
        padding: 0;
        list-style: none;
        @include spacing(padding, large, horizontal);
        @include spacing(margin-bottom, large);

        &__subscription {
          display: flex;
          justify-content: space-between;
          align-items: flex-start;
          @include spacing(padding, medium, vertical);
          border-bottom: 1px solid $mid-grey;

          &--extra {
            @include spacing(padding, tiny, vertical);
            position: relative;
            top: -40px;
            background-color: white;
          }

          &__title {
            @include type(tertiary-title);
            font-weight: 500;

            span {
              @include type(small);
            }

            &__description {
              @include type(small);
            }
          }

          &__price {
            margin-bottom: 0;
          }
        }
      }

      &__least {
        @include spacing(padding, medium, horizontal);
        @include type(legal);
        text-decoration: underline;
      }

      &__legal {
        @include spacing(padding, medium, horizontal);
      }
    }

    &__button {
      display: block;
      text-align: center;
    }
  }
}
