@import '../../styles/import.scss';

.login {
  &__form {
    @include spacing(margin-top, 3x-large);

    &__button {
      display: inline-block;
    }
  }

  &__illustration {
    float: right;
    @include spacing(margin-top, 5x-large);
  }

  &__select {
    @include spacing(margin-bottom, large);
  }
}
