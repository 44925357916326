/* typography.scss
 * Genereal text styling
 * ---------------------------------------------- */

// font-faces
@font-face {
  font-family: 'Etica';
  src: url('//s.c.dk/fonts/lft_etica_light-webfont.woff2') format('woff2'),
    url('//s.c.dk/fonts/lft_etica_light-webfont.woff') format('woff');
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Etica';
  src: url('//s.c.dk/fonts/lft_etica_reg-webfont.woff2') format('woff2'),
    url('//s.c.dk/fonts/lft_etica_reg-webfont.woff') format('woff');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Etica';
  src: url('//s.c.dk/fonts/lft_etica_semibold-webfont.woff2') format('woff2'),
    url('//s.c.dk/fonts/lft_etica_semibold-webfont.woff') format('woff');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Etica';
  src: url('//s.c.dk/fonts/lft_etica_bold-webfont.woff2') format('woff2'),
    url('//s.c.dk/fonts/lft_etica_bold-webfont.woff') format('woff');
  font-weight: 800;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Etica';
  src: url('//s.c.dk/fonts/lft_etica_extrabold-webfont.woff2') format('woff2'),
    url('//s.c.dk/fonts/lft_etica_extrabold-webfont.woff') format('woff');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

a {
  color: $link-color;
  text-decoration: none;
  &:hover {
    color: $link-color;
  }
}

strong,
b {
  font-weight: 600;
}

// headings

%heading {
  padding: 0;
  @include spacing(margin-bottom, tiny);
  font-weight: 500;
  line-height: 1.2;
  color: $grey-ten;
}

h1,
.h1,
.h1a {
  @extend %heading;
  font-weight: 900;
  font-size: 32px;
  line-height: 1.25;
}

.h1b {
  @extend %heading;
  font-weight: 900;
  font-size: 24px;
  line-height: 1.67;
}

.h1c {
  @extend %heading;
  font-size: 24px;
  line-height: 1.67;
}

h2,
.h2,
.h2a {
  @extend %heading;
  font-size: 20px;
}

.h2b {
  @extend %heading;
  font-weight: 400;
  font-size: 20px;
}

h3,
.h3,
.h3a {
  @extend %heading;
  font-weight: 600;
  font-size: 16px;
  line-height: 1.5;
}

.h3b {
  @extend %heading;
  font-weight: 400;
  font-size: 16px;
  line-height: 1.5;
}

.h3c {
  @extend %heading;
  font-weight: 400;
  font-size: 14px;
  line-height: 1.71;
}

h4,
.h4 {
  @extend %heading;
  font-weight: 400;
  font-size: 12px;
  line-height: 2;
}

h5,
.h5,
.tag {
  @extend %heading;
  font-weight: 600;
  font-size: 10px;
  line-height: 1.6;
  letter-spacing: 2px;
  text-transform: uppercase;
  // text-shadow: 0 1px 3px rgba(0, 0, 0, 0.5);
}

.accented-text {
  color: $accent;
}
