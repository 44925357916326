@import '../variables/typography';
@import '../variables/breakpoints';

$base-unit: 0.5rem;

@mixin use-media-breakpoint($unit) {
  // A basic wrapper allowing the usage of the Bootstrap
  // mixin with a single combined size and range variable.
  $units: str-split($unit, '-');
  $size: nth($units, 1);
  $size-range: nth($units, 2);

  @if $size-range == 'up' {
    @include media-breakpoint-up($size) {
      @content;
    }
  } @else if $size-range == 'down' {
    @include media-breakpoint-down($size) {
      @content;
    }
  } @else {
    @include media-breakpoint-only($size) {
      @content;
    }
  }
}

@mixin map-declaration($property, $value) {
  // Only multiply values in REM units
  @if $property ==
    'font-size' or
    $property ==
    'line-height' or
    $property ==
    'letter-spacing'
  {
    #{$property}: #{$value * $base-unit};
  } @else {
    #{$property}: #{$value};
  }
}

@mixin type($name) {
  $type: map-get($types, $name);

  @each $property, $value in $type {
    @if $property == 'sizes' {
      // Iterate size variations
      @each $size, $declarations in $value {
        @include use-media-breakpoint($size) {
          @each $property, $value in $declarations {
            @include map-declaration($property, $value);
          }
        }
      }
    } @else {
      @include map-declaration($property, $value);
    }
  }
}
