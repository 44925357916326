@import '../../styles/import.scss';

.user {
  &__section {
    padding: 24px;
    display: block;
    box-shadow: 0 0 16px 0 rgba(0, 0, 0, 0.2);
    border-radius: 16px;
    max-width: 1200px;
    width: calc(100% - 48px);
    margin: 24px auto;

    h1 {
      margin-top: 0;
      cursor: pointer;
      font-size: 24px;
    }
  }
}
