@import '../../styles/import';

.accordion {
  border: 1px solid $mid-grey;
  border-radius: 8px;

  &__item {
    padding: 0 24px 24px 24px;
    border-bottom: 1px solid $mid-grey;

    &:last-child {
      border-bottom: none;
    }

    &__header {
      cursor: pointer;
      padding-top: 24px;
      $title: 0;

      &__title {
        margin-top: 0;
        color: #a2a2a2;
        @include type(secondary-title);
        $title: &;
      }

      &--open {
        #{$title} {
          color: $copy-color;
        }
      }
    }
  }
}

.ReactCollapse--collapse {
  transition: height ease-in-out 250ms;
}
