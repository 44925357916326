@import '../../styles/import.scss';

.order-lookup {
  @include spacing(margin-bottom, x-large);

  &__search {
    position: relative;
    z-index: 200;
    margin-bottom: 48px;
  }

  &__error {
    text-align: center;
  }

  &__pdf {
    position: fixed;
    z-index: 999;
    padding-top: 108px;
    background-color: rgba(white, 0.6);
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: scroll;

    &__close {
      position: fixed;
      display: block;
      z-index: 2;
      height: 48px;
      width: 48px;
      top: 12px;
      right: 12px;
      cursor: pointer;
      display: flex;

      &::before,
      &::after {
        position: absolute;
        top: calc(50% - 1px);
        left: calc(50% - 12px);
        content: '';
        display: block;
        width: 24px;
        height: 2px;
        background-color: black;
        transform-origin: center;
        transform: rotate(45deg);
      }

      &::after {
        transform: rotate(-45deg);
      }
    }
  }
}
