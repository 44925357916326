@import '../../styles/import.scss';

.contact {
  max-width: 700px;
  margin-left: auto;
  margin-right: auto;
  border: 1px solid $mid-grey;
  border-radius: 8px;
  @include spacing(padding, large);
  @include spacing(margin-top, large);

  &__title {
    @include type(default-bold);
  }

  &__input {
    max-width: 400px;
  }

  &__button {
    @include spacing(margin-top, large);
  }
}
