@import '../variables/spacing';

$base-unit: 0.5rem;

@function with-unit($size) {
  @return map-get($spacings, $size) * $base-unit;
}

@mixin spacing($property: 'padding', $size: 'base', $direction: '') {
  $value: with-unit($size);

  @if ($direction == 'horizontal') {
    #{$property}-left: $value;
    #{$property}-right: $value;
  } @else if ($direction == 'vertical') {
    #{$property}-top: $value;
    #{$property}-bottom: $value;
  } @else {
    #{$property}: $value;
  }
}
