//Variables needed for bootstrap (FOR NOW) - we may want to fix bootstrap all together
$displays: none, inline, inline-block, block, table, table-row, table-cell, flex,
  inline-flex !default;

// YouSee color palette
// --------------------------------------------------

$primary-green: #1fab2e;
$light-blue: #f1f7f9;

// Feedback:
$feedback-red: #d31a20;
$feedback-light-red: rgba(#d31a20, 0.1);
$feedback-blue: #266df5;
$feedback-light-blue: rgba(#266df5, 0.1);
$feedback-green: $primary-green;
$feedback-light-green: rgba($primary-green, 0.1);

// Label colors:
$label-blue: #327ea4;
$label-green: #5d7e64;
$label-gold: #8a723d;

// Copy:
$copy-color: #191919;

$black: #000000;

// whites
$explore-white: #ffffff;

// greys
$grey: #f2f2f2;
$light-grey-one: #fafafa;
$light-grey-two: #f0f0f0;
$mid-grey: #d9d9d9;
$mid-grey-two: #a2a2a2;
$cool-grey: #a2a3a6;
$dark-grey: #484848;
$dark-grey-two: #767676;
$legal: #929292;
$grey-ten: #191919;

// blues
$dark-sky-blue: #27a4d7;

// reds
$lipstick: #d31a20;
$faded-lipstick: #fae8e8;
$yellow: #ffb700;

// greens
$digital-grass: #1fab2e;
$lightish-green: #56e856;
$digital-midnight: #002219;
$light-green: #ebf9ec;
$grey-green: #b5c9b9;
$digital-moss: #0c4d33;
$campaign: #29443d;
$campaign-light: #77997c;

// Accent Colors
$accent: $digital-grass;
$accent-dark: $digital-midnight;
$accent-light: $lightish-green;

// Links
$link-color: $accent;
$link-color-hover: $accent;

//Tooltip
$tooltip-color: #484848;

// shadow
$shadow-color: rgba(0, 0, 0, 0.2);

// Slider (react slick defaults)
$slider-transition-duration: 500ms;
$slider-transition-timing-function: ease;

// alert
$bold-orange: #ff8a00;
$soft-orange: #fff7e5;
$bold-blue: #266df5;
$soft-blue: #e8f0ff;
$bold-red: #d31a20;
$soft-red: #fae8e8;

// Base styles
// --------------------------------------------------

// Baseline grid
$basefont: 14px;
$baseline: 15px;

// spacing
$def-padding: 24px;
$def-padding-tablet: 40px;
$def-padding-desktop: 48px;

// Grid system
// --------------------------------------------------

@function grid-map(
  $columns,
  $column-width,
  $gutter-width,
  $outer-margin: 0,
  $prefix: 'sm'
) {
  @return (
    columns: $columns,
    column-width: $column-width,
    gutter-width: $gutter-width,
    outer-margin: $outer-margin,
    prefix: $prefix
  );
}

$grid: (
  phone: grid-map(6, 49px, 8px, 20px, 'sm'),
  tablet: grid-map(6, 104px, 16px, 32px, 's'),
  tablet-landscape: grid-map(12, 64px, 16px, 40px, 'm'),
  desktop: grid-map(12, 88px, 16px, 24px, 'l'),
);

@function screen-breakpoint($screen: phone) {
  $map: map-get($grid, $screen);
  @return map-get($map, columns) *
    (map-get($map, column-width) + map-get($map, gutter-width)) -
    map-get($map, gutter-width) + 2 * map-get($map, outer-margin);
}

// Bootstrap grid
// --------------------------------------------------

$enable-grid-classes: true;

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 1024px,
  xl: 1280px,
);

$container-max-widths: (
  sm: none,
  md: none,
  lg: none,
  xl: 1248px // 1216px + 32px gutter,
);

$grid-columns: 12;
$grid-gutter-width: 32px;

// Misc
// --------------------------------------------------

$border-radius-base: 12px !default;

$border-radius-xsmall: 4px !default;
$border-radius-small: 8px !default;
$border-radius-medium: 16px !default;
$border-radius-large: 24px !default;

// Below this breakpoint everything is being
// scaled slightly to better fit the viewport.
$scale-breakpoint: 369px;

//New colors from new CVI

$backgoundColor: #f0f4f1;

$blå: #327ea4;
$grøn: #5d7e64;
$mørkegrøn: #003025;
$brun: #8a723d;
$baby-blå: #e4eef4;
$lysbrun: #dbcdad;
$tekstGrøn: #1a8924;
