@import '../../styles/import';

.component-textinput {
  $input-height: 48px;
  $border: 0;
  $label: 0;

  &__label {
    $label: &;
    display: block;
    margin-bottom: 32px;
    position: relative;

    &__text {
      margin-left: 0;
      margin-bottom: 0;
      color: $grey-ten;
      text-transform: uppercase;
      margin-bottom: 8px;
      display: block;

      @include type(label);
    }

    &__approved {
      position: absolute;
      right: 20px;
      top: 40px;
    }

    &__input {
      box-sizing: border-box;
      width: 100%;
      height: $input-height;
      border: 0;
      border-radius: $border-radius-large;
      padding: 0 $def-padding;
      text-align: left;
      color: $grey-ten;
      border: 1px solid $grey-ten;
      box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.24);
      transition: box-shadow 250ms ease-in-out,
        background-color 250ms ease-in-out;

      @include type(default);

      &::placeholder {
        opacity: 0.5;
      }

      &::-ms-clear {
        display: none;
      }

      // for number input
      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }

      /* In lieu of novalidate attribute which we can't use without form tags */
      &:invalid {
        box-shadow: none;
      }

      &:focus,
      &:hover,
      &:active,
      &:focus-visible {
        border: 2px solid $digital-grass;
      }

      &[readOnly] {
        opacity: 0.6;
        cursor: default;
        box-shadow: none;
      }

      &--error {
        border: 3px solid $feedback-red;
      }
    }

    &__error-text {
      @include spacing(margin-top, small);
      @include type(small);

      color: $feedback-red;
      font-style: italic;
      text-align: left;
    }
  }

  // email
  &.component-textinput-email {
    .input-wrapper {
      position: relative;

      input {
        padding-left: 36px;
      }

      &::before {
        position: absolute;
        width: $def-padding;
        height: 2 * $def-padding;
      }
    }
  }

  .input-indicator {
    position: absolute;
    font-size: 1rem;
    font-weight: bold;
    text-align: center;
    vertical-align: middle;
    border: none;
    background: none;
    line-height: $input-height;
    width: 24px;
    right: 12px;
    &.error {
      color: $lipstick;
      pointer-events: none;
    }
    /* Waiting to find out from UX guys if we'll use this
        &.clear-field {
            cursor: pointer;
        }
        */
  }

  &--no-margin {
    #{$label} {
      @include spacing(margin-bottom, none);
    }
  }
}
