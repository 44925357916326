/* Layout.scss
*
* ----------- */

html,
body {
  font-size: 16px;
  background-color: white !important;
}

img {
  max-width: 100%;
  height: auto;
}

input {
  font-family: 'Etica', sans-serif;
  appearance: none;
  caret-color: $digital-grass;

  &:invalid {
    border-radius: 24px;
  }
}

.sticky {
  position: sticky;
  top: calc(4.5em + 24px);
  transition: top 400ms !important;

  &:not(.top-navigation) {
    top: calc(4.5em + 24px + 79px);
  }
}

body {
  &.body--scroll1 {
    .sticky {
      position: sticky;
      top: 24px;
    }
  }
}

small {
  font-size: 100%;
}

.container--scale-content {
  @media (max-width: $scale-breakpoint) {
    padding: 0;
    transform-origin: top;
  }
  @include scale-content;
}

.App {
  @include type(default);
  color: $grey-ten;
}

body {
  font-family: 'Etica', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  margin: 0;
}

.contentarea {
  flex-grow: 1;
}

.legal {
  @include type(legal);
}

h1 {
  @include type(primary-title);
}

h3 {
  @include type(tertiary-title);
  color: $digital-grass;
}
