@import '../../styles/import';

.header {
  position: relative;
  margin-bottom: 48px;
  display: flex;
  justify-content: flex-end;

  &__logo-container {
    margin-right: auto;
  }

  &__logo {
    position: absolute;
    left: 16px;
    top: 32px;
  }

  &__profile {
    display: flex;
    @include spacing(padding, small);
    @include spacing(padding-left, large);
    list-style: none;
    margin: 0;

    &__each {
      @include spacing(margin-left, x-large);

      cursor: pointer;
      width: 48px;
      height: 48px;
      background-color: $primary-green;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      position: relative;

      &:last-child {
        @include spacing(margin-right, medium);
      }

      p {
        position: absolute;
        display: block;
        width: max-content;
        bottom: -24px;
        transform: translatey(100%);
      }

      svg {
        margin: auto;
      }
    }
  }
}
