@import 'import';

/* Forms.scss
* Base styles for various input types, form layouts, and states
* ------------------------------------------------------------- */

.input-error {
  background-color: $faded-lipstick;
}

.input-error-text {
  color: $lipstick;
  font-size: 0.875rem;
  font-weight: normal;
  @include spacing(margin-top, x-small);
  @include spacing(margin-left, medium);
  @include spacing(margin-right, medium);
}

input:disabled {
  background-color: $light-grey-two;
  box-shadow: none;
  cursor: not-allowed;
}

label,
button,
input {
  cursor: pointer;
}

.date-picker-input {
  height: 48px;
  border: 0;
  border-radius: $border-radius-large;
  padding: 0 $def-padding;
  color: $copy-color;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.24);
  transition: box-shadow 250ms ease-in-out, background-color 250ms ease-in-out;

  &::placeholder {
    opacity: 0.5;
  }

  &::-ms-clear {
    display: none;
  }

  &:focus {
    @include selectable--selected;
  }
}

.date-picker-calendar {
  border: 0;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.4);
  &__port-date {
    background-color: $digital-grass;
    font-weight: bold;
  }
  .react-datepicker {
    &__header {
      border: 0;
    }
  }
}
