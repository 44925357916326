@import '../../styles/import.scss';
$label: 0;

.subscriptions {
  &__table {
    border-spacing: 0 24px;
    width: 100%;
    font-weight: bold;
    white-space: nowrap;
    overflow: hidden;
    padding: 0 12px;
    position: relative;
    max-width: 1200px;
    margin: auto;

    &::after {
      content: '';
      position: absolute;
      height: 56px;
      width: 100%;
      bottom: 0;
      background-color: white;
    }

    &__label {
      position: absolute;
      background-color: $grøn;
      color: white;
      @include spacing(padding, small, horizontal);
      @include spacing(padding, tiny, vertical);
      @include type(default-semibold);
      border-radius: 4px;
      top: -16px;

      &--large {
        @include spacing(padding, small, horizontal);
        @include spacing(padding, small, vertical);
        bottom: 0;
        top: auto;
      }
    }

    &__spacer {
      height: 75px;
      box-shadow: none !important;
      border: none;
    }

    &__name {
      background-color: $mørkegrøn;
      white-space: break-spaces;
      min-width: 150px;
      color: white;
      text-align: left;
    }

    &__right {
      text-align: right;
    }

    &__head {
      &__top {
        border-radius: 8px;
        position: relative;
        top: 48px;

        td {
          @include spacing(padding, small);
          background-color: #d3dfd5;
          border: 2px 0 solid white;
          border-left: 2px solid white;
          border-right: 2px solid white;
          text-align: center;

          &:first-child,
          &:last-child {
            background: none;
          }

          &:nth-child(2) {
            border-radius: 8px 0 0 0;
            border-left: 0px;
          }

          &:nth-child(5) {
            border-radius: 0 8px 0 0;
            border-right: 0px;
          }
        }
      }

      &__bottom {
        position: relative;
        top: 24px;

        td {
          text-align: center;
          background: #f0f4f1;
          @include spacing(padding, small);
          font-weight: normal;
          font-size: 12px;
          position: relative;
          border-right: 2px solid white;
          border-left: 2px solid white;

          &:nth-child(2) {
            border-left: none;
          }

          &:nth-child(7) {
            border-right: none;
          }

          &::after {
            content: '';
            background-color: #f0f4f1;
            display: block;
            position: absolute;
            left: 0;
            top: 100%;
            width: 100%;
            height: 200vh;
          }

          &:first-child,
          &:last-child {
            background: none;

            &::after {
              display: none;
            }
          }
        }
      }
    }

    tbody {
      tr {
        border-radius: 8px;
        box-shadow: 0px 0px 11.283732414245605px 0px #00000029;
        position: relative;
        z-index: 1;
        background-color: white;
      }

      td {
        @include spacing(padding, small);
        text-align: center;

        &:first-child {
          border-radius: 8px 0 0 8px;
          text-align: left;
        }

        &:nth-child(2) {
          padding: 24px;
        }

        &:last-child {
          border-radius: 0 8px 8px 0;
        }

        img {
          width: 32px;
          height: 32px;
        }
      }
    }
  }

  &__button {
    @include spacing(margin-left, small);
  }
}
