@import '../../../node_modules/react-datepicker/src/stylesheets/datepicker.scss';
@import '../../styles/import.scss';

/*
 *  This stylesheet overrides styling for the vendor imported above.
 */

.react-datepicker {
  border-radius: 0;
  border: 1px solid black;
  min-height: 260px;

  &__input-container {
    display: block;
    clear: both;

    &::after {
      pointer-events: none;
      position: absolute;
      font-size: xx-large;
      padding-left: 6px;
      top: -4px;
      left: 0;
      margin-left: 24%;
    }

    input,
    .react-datepicker-ignore-onclickoutside {
      height: 48px;
      border: 0;
      border-radius: 36px;
      padding: 0 8px;
      transition: box-shadow 250ms ease-in-out,
        background-color 250ms ease-in-out;
      cursor: pointer;
      border: 2px solid #191919;
      padding: 0 24px;

      @media (max-width: $screen-sm-max) {
        //display: none;
        width: 100%;
      }

      background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect x='2' y='5' width='20' height='15' rx='1.83102' stroke='%23191919' stroke-width='2'/%3E%3Cpath d='M4.7998 6.4V3' stroke='%23191919' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M2 9L22 9' stroke='%23191919' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M19.2002 6.4V3' stroke='%23191919' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
      background-repeat: no-repeat;
      background-position: calc(100% - 24px) center;

      &::placeholder {
        opacity: 0.5;
      }

      &::-ms-clear {
        display: none;
      }
    }
  }

  &__triangle {
    border-bottom-color: black !important;

    &::before {
      border-bottom-color: black !important;
    }
  }

  &__header {
    background-color: black;
    border-radius: 0;
  }

  &__navigation--previous {
    border-right-color: $digital-grass;

    &:hover {
      border-right-color: $digital-grass;
    }
  }

  &__navigation--next {
    border-left-color: $digital-grass;

    &:hover {
      border-left-color: $digital-grass;
    }
  }

  &__current-month {
    color: white;
  }

  &__day {
    margin: 0;
    border-radius: 0;

    &:hover {
      border-radius: 0;
      background-color: $grey-green;
    }
  }

  &__day-name {
    margin: 0;
    color: white;
  }

  &__day--in-range {
    color: $digital-grass;
    background-color: $lightish-green;

    &:hover,
    &:focus {
      color: white;
      background-color: $digital-grass;
    }

    &.react-datepicker__day--selected {
      color: white;
      background-color: $digital-grass;
    }
  }
}

.date-picker-calendar {
  border: 0 !important;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.4);
  &__port-date {
    background-color: green;
    font-weight: bold;
  }
  .react-datepicker {
    font-size: 14px;
    line-height: 28px;

    &__header {
      border: 0;
      background-color: white;
    }

    &__triangle {
      display: none;
    }

    &__navigation-icon {
      &::before {
        border-width: 2px 2px 0 0;
        border-color: #191919;
      }
    }

    &__current-month {
      text-transform: capitalize;
      padding: 4px 0;
    }

    &__navigation {
      top: 9px;

      &--next {
        right: 24px;
      }

      &--previous {
        left: 24px;
      }
    }

    &__day-name {
      color: #5d7e64;
      padding: 0 6px;
    }

    &__day {
      border-radius: 50%;
      padding: 6px;
      line-height: 1rem;

      &:hover {
        border-radius: 50%;
      }

      &--keyboard-selected,
      &--selected {
        background-color: #f0f4f1;
        border-radius: 50%;
        color: #5d7e64;

        &:hover {
          border-radius: 50%;
          background-color: #f0f4f1;
        }
      }
    }
  }
}
